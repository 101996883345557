import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TemplateDialogConfirmComponent } from '@presentation/molecules/template-dialog-confirm/template-dialog-confirm.component';
import { TemplateDialogQuestionComponent } from '@presentation/molecules/template-dialog-question/template-dialog-question.component';
import { TemplateDialogCancelComponent } from '@presentation/molecules/template-dialog-cancel/template-dialog-cancel.component';
import { TemplateDialogNotificationComponent } from '@presentation/molecules/template-dialog-notification/template-dialog-notification.component';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    TemplateDialogConfirmComponent,
    TemplateDialogQuestionComponent,
    TemplateDialogCancelComponent,
    TemplateDialogNotificationComponent,
  ],
  providers: [PrimeNGConfig],
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(
    private config: PrimeNGConfig,
  ) {}

  ngOnInit() {
    this.translate()
  }

  translate() {
    this.config.setTranslation({
      accept: 'Aceptar',
      reject: 'Cancelar',
      emptyFilterMessage: 'No se encontraron resultados',
      emptyMessage: 'No hay datos disponibles',
      monthNames: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
      monthNamesShort: [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic',
      ],
      dayNames: [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
      ],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
      weekHeader: 'Semana',
      today: 'Hoy',
      clear: 'Limpiar',
    })
  }
}
