<p-confirmDialog #cd [key]="dialogKey['QUESTION']">
  <ng-template pTemplate="headless" let-message>
    <span
      class="material-symbols-outlined text-2xl text-gray-200 font-bold fixed top-0 right-0 p-2 cursor-pointer"
      (click)="cd.reject()"
    >
      close
    </span>

    <div
      class="flex flex-column align-items-center surface-overlay border-round"
      [style]="{ width: '100%', maxWidth: '400px', padding: '24px'}"
    >
      <!-- NOTE: Se usa el atributo `rejectionIcon` para darle color de fondo al ícono -->
      <div
        class="border-circle inline-flex justify-content-center align-items-center  outline-none"
        [style]="{ width: '32px', height: '32px' }"
        [ngClass]="message.rejectIcon ?? 'bg-primary-600'"
      >
        <span class="material-symbols-outlined text-white" [style]="{fontSize: '20px'}">
          {{ message.icon }}
        </span>
      </div>

      <span class="font-medium block mb-2 mt-3 text-primary-900" [style]="{fontSize: '16px'}">
        {{ message.header }}
      </span>

      <p class="mb-0 text-gray-500 text-center">{{ message.message }}</p>

      <div
        class="flex align-items-center gap-2 mt-4 w-full justify-content-center"
      >
        <button
          pButton
          [outlined]="true"
          (click)="cd.reject()"
          class="w-6 justify-content-center"
        >
        <span class="font-medium">{{ message.rejectLabel }}</span>
        </button>

        <button
          pButton
          (click)="cd.accept()"
          class="w-6 justify-content-center"
        >
          <span class="font-medium">{{ message.acceptLabel }}</span>
        </button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
