import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CreatePlatformContractsQuery,
  CreatePlatformContractsResponse,
} from '@domain/services/platform-contracts/dto/create.dto';
import {
  GetPlatformContractsQuery,
  GetPlatformContractsResponse,
} from '@domain/services/platform-contracts/dto/get.dto';
import {
  ListPlatformContractsQuery,
  ListPlatformContractsResponse,
} from '@domain/services/platform-contracts/dto/list.dto';
import { getPriceRangeOfPlatformContractsResponse } from '@domain/services/platform-contracts/dto/price.dto';
import { ShowPlatformContractsResponse } from '@domain/services/platform-contracts/dto/show.dto';
import {
  UpdatePlatformContractsQuery,
  UpdatePlatformContractsResponse,
} from '@domain/services/platform-contracts/dto/update.dto';
import { PlatformContractsRepository } from '@domain/services/platform-contracts/repository/platform-contracts.repository';
import { Observable } from 'rxjs';
import { URL_BACKEND } from 'src/app/core/config/url';

@Injectable({
  providedIn: 'root',
})
export class PlatformContractsService extends PlatformContractsRepository {
  private urlApi = `${URL_BACKEND}/management/contract`;

  constructor(private http: HttpClient) {
    super();
  }

  override show(id: number): Observable<ShowPlatformContractsResponse> {
    return this.http.get<ShowPlatformContractsResponse>(`${this.urlApi}/show`, {
      params: { id: id },
    });
  }

  override get(
    query?: GetPlatformContractsQuery | undefined,
  ): Observable<GetPlatformContractsResponse> {
    return this.http.get<GetPlatformContractsResponse>(`${this.urlApi}/get`, {
      params: { ...query },
    });
  }

  override list(
    query?: ListPlatformContractsQuery | undefined,
  ): Observable<ListPlatformContractsResponse> {
    return this.http.get<ListPlatformContractsResponse>(`${this.urlApi}/list`, {
      params: { ...query },
    });
  }

  override create(
    payload: CreatePlatformContractsQuery,
  ): Observable<CreatePlatformContractsResponse> {
    const data = new FormData();

    this.loadFormData(data, payload);

    return this.http.post<CreatePlatformContractsResponse>(
      `${this.urlApi}`,
      data,
    );
  }

  override update(
    payload: UpdatePlatformContractsQuery,
  ): Observable<UpdatePlatformContractsResponse> {
    const data = new FormData();

    data.append('id', payload.id.toString());
    this.loadFormData(data, payload);

    return this.http.post<UpdatePlatformContractsResponse>(
      `${this.urlApi}/update`,
      data,
    );
  }

  private loadFormData(
    data: FormData,
    payload: UpdatePlatformContractsQuery | CreatePlatformContractsQuery,
  ) {
    data.append('name', payload.name);
    data.append('description', payload.description);
    data.append('price', payload.price.toString());
    data.append(
      'taxonomy_category_id',
      payload.taxonomy_category_id.toString(),
    );
    data.append('status', payload.status.toString());

    for (let variable of payload.entity_variable) {
      data.append('entity_variable[]', variable.toString());
    }
    if (payload.file) data.append('file', payload.file!);
  }

  override getPriceRange(): Observable<getPriceRangeOfPlatformContractsResponse> {
    return this.http.get<getPriceRangeOfPlatformContractsResponse>(`${this.urlApi}/price`);
  }

}
