import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ListContractsGeneratedQuery, ListContractsGeneratedResponse } from "@domain/services/contract-generated/dto/list.dto";
import { ContractsGeneratedRepository } from "@domain/services/contract-generated/repository/contract-generated.repository";
import { Observable } from "rxjs";
import { URL_BACKEND } from "src/app/core/config/url";


@Injectable({
  providedIn: 'root',
})
export class ContractsGeneratedService extends ContractsGeneratedRepository {
  private urlApi = `${URL_BACKEND}/many/contract/customer`;

  constructor(private http: HttpClient) {
    super();
  }

  override list(
    query?: ListContractsGeneratedQuery | undefined,
  ): Observable<ListContractsGeneratedResponse> {
    return this.http.get<ListContractsGeneratedResponse>(`${this.urlApi}/list`, {
      params: { ...query },
    });
  }

}