import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { CoreRouteNames } from '@presentation/pages/core/core.routenames';
import { AuthRepository } from '@domain/services/auth/repository/auth.repository';
import { PermissionsCodes } from 'src/app/core/constant/permissions.constant';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  model: any[] = [];
  itemLogout: any = {
    label: '',
    items: [
      {
        label: 'Cerrar sesión',
        icon: 'logout',
        command: () => {
          this.authService.logout();
        },
      },
    ],
  };

  constructor(
    public layoutService: LayoutService,
    public authService: AuthRepository
  ) {}

  validatePermission(permission: string) {
    return this.authService.comparePermission(permission);
  }


  ngOnInit() {
    this.model = [
      {
        label: '',
        items: [
          {
            label: 'Contratos de la plataforma',
            icon: 'star',
            routerLink: [CoreRouteNames.platformContracts.local],
            visible: this.validatePermission(PermissionsCodes.MANAGEMENT_CONTRACT_VIEW),
          },

          {
            label: 'Categorías',
            icon: 'category',
            routerLink: [CoreRouteNames.categories.local],
            visible: this.validatePermission(PermissionsCodes.TAXONOMY_CATEGORY_VIEW),
          },

          {
            label: 'Variables',
            icon: 'bookmarks',
            routerLink: [CoreRouteNames.variables.local],
            visible: this.validatePermission(PermissionsCodes.ENTITY_VARIABLE_VIEW),
          },

          {
            label: 'Clientes',
            icon: 'group',
            routerLink: [CoreRouteNames.customers.local],
            visible: this.validatePermission(PermissionsCodes.USER_CUSTOMER_VIEW),
          },

          {
            label: 'Contratos generados',
            icon: 'assignment',
            routerLink: [CoreRouteNames.contractGenerated.local],
            visible: this.validatePermission(PermissionsCodes.MANAGEMENT_CONTRACT_GENERATED_VIEW),
          },

          {
            label: 'Usuarios',
            icon: 'account_circle',
            routerLink: [CoreRouteNames.users.local],
            visible: this.validatePermission(PermissionsCodes.USER_PERSON_VIEW),
          },

          {
            label: 'Preguntas frecuentes',
            icon: 'contact_support',
            routerLink: [CoreRouteNames.utilitiesFaq.local],
            visible: this.validatePermission(PermissionsCodes.UTILITY_FAQ_VIEW),
          },
        ],
      },
    ];
  }
}
