import { Routes } from '@angular/router';
import { AppLayoutComponent } from './presentation/layout/app.layout.component';
import { AppRouteNames } from './app.routenames';
import { LoginGuard } from './core/guards/login.guard';
import { ErrorRouteNames } from '@presentation/pages/error-pages/error.routenames';

export const routes: Routes = [
  {
    path: '',
    redirectTo: AppRouteNames.access,
    pathMatch: 'full',
  },
  {
    path: AppRouteNames.access,
    loadChildren: () =>
      import('./presentation/pages/access/access.routes').then((m) => m.routes),
    canActivate:[LoginGuard]
  },
  {
    path: AppRouteNames.core,
    component: AppLayoutComponent,
    loadChildren: () =>
      import('./presentation/pages/core/core.routes').then((m) => m.routes),
  },
  {
    path: AppRouteNames.error,
    loadChildren: () =>
      import('./presentation/pages/error-pages/error.routes').then((m) => m.routes),
  },
  {
		path: '**',
    redirectTo: ErrorRouteNames.error_404.global
  }
];
