import { EventEmitter, Injectable } from '@angular/core';
import {
  LoginRequest,
  LoginResponse,
  UserLogged,
} from '@domain/services/auth/dto/login.dto';
import { URL_BACKEND } from 'src/app/core/config/url';
import { AuthRepository } from '@domain/services/auth/repository/auth.repository';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AccessRouteNames } from '@presentation/pages/access/access.routenames';
import { ChangeFirstPasswordRequest, ChangeFirstPasswordResponse } from '@domain/services/auth/dto/changeFirstPassword.dto';

@Injectable({ providedIn: 'root' })
export class AuthService extends AuthRepository {
  constructor(private http: HttpClient, private router: Router) {
    super();
  }
  changeToken = new EventEmitter();

  override login(data: LoginRequest): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${URL_BACKEND}/auth/login`, data);
  }

  override logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('logued');
    this.router.navigateByUrl(AccessRouteNames.login.global);
  }

  override isNewPassword(): boolean {
    const user: UserLogged = JSON.parse(localStorage.getItem('user') || '{}');
    return !!user.renew_password ? true : false;
  }

  override updateIsNewPasswordStatus() {
    const user: UserLogged = JSON.parse(localStorage.getItem('user') || '{}');
    user.renew_password = 0;
    localStorage.setItem('user', JSON.stringify(user));
  }

  override getToken(): string {
    const token = localStorage.getItem('token') || '';
    return token;
  }

  override isLogged(): boolean {
    const logued = localStorage.getItem('logued');
    return !!logued;
  }

  override changeFirstPassword(data: ChangeFirstPasswordRequest): Observable<ChangeFirstPasswordResponse> {
    return this.http.put<ChangeFirstPasswordResponse>(`${URL_BACKEND}/password/reset`, data);
  }

  override comparePermission(permission: string): boolean {
    const user: UserLogged = JSON.parse(localStorage.getItem('user') || '{}');
    const userPermissions = user.capability_permission;
    return userPermissions.includes(permission);
  }

}
