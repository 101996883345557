import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CategoryRepository } from "@domain/services/categories/category-repository";
import { GetCategoryResponse, ListCategoryQuery, ListCategoryResponse } from "@domain/services/categories/list-category.dto";
import { ShowCategoryResponse } from "@domain/services/categories/read-category.dto";
import { WriteCategoryPayload, WriteCategoryResponse } from "@domain/services/categories/write-category.dto";
import { Observable } from "rxjs";
import { URL_BACKEND } from "src/app/core/config/url";


@Injectable({
  providedIn: 'root',
})
export class CategoryRepositoryService extends CategoryRepository {
  urlApi = `${URL_BACKEND}/taxonomy/category`;

  constructor(private http: HttpClient) {
    super();
  }

  override show(id: number): Observable<ShowCategoryResponse> {
    return this.http.get<ShowCategoryResponse>(`${this.urlApi}/show`, {
      params: { id: id },
    });
  }

  override get(query?: any | undefined): Observable<GetCategoryResponse> {
    return this.http.get<GetCategoryResponse>(`${this.urlApi}/get`, {
      params: { ...query },
    });
  }

  override list(
    query?: ListCategoryQuery | undefined
  ): Observable<ListCategoryResponse> {
    return this.http.get<ListCategoryResponse>(`${this.urlApi}/list`, {
      params: { ...query },
    });
  }

  override create(payload: WriteCategoryPayload): Observable<WriteCategoryResponse> {
    return this.http.post<WriteCategoryResponse>(this.urlApi, payload);
  }

  override update(payload: WriteCategoryPayload): Observable<WriteCategoryResponse> {
    return this.http.put<WriteCategoryResponse>(this.urlApi, payload);
  }

}