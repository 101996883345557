<p-confirmDialog #cd [key]="dialogKey['CONFIRM']">
  <ng-template pTemplate="headless" let-message>
    <span
      class="material-symbols-outlined text-2xl text-gray-200 font-bold fixed top-0 right-0 p-2 cursor-pointer"
      (click)="cd.accept()"
    >
      close
    </span>

    <div
      class="flex flex-column align-items-center p-5 surface-overlay border-round"
      [style]="{ width: '100%', maxWidth: '400px', padding: '24px'}"
    >
      <!-- NOTE: Se usa el atributo `rejectionIcon` para darle color de fondo al ícono -->
      <div
        class="border-circle inline-flex justify-content-center align-items-center  outline-none"
        [style]="{ width: '32px', height: '32px' }"
        [ngClass]="message.rejectIcon ?? 'bg-primary'"
      >
        <span class="material-symbols-outlined text-white" [style]="{fontSize: '20px'}">
          {{ message.icon }}
        </span>
      </div>

      <span class="font-medium block mb-2 mt-3 text-primary-900" [style]="{fontSize: '16px'}">
        {{ message.header }}
      </span>

      <div
        class="flex flex-column"
        style="gap: 4px"
        *ngIf="message.message.includes('\n'); else singleLineMessage"
      >
        <ng-container
          *ngFor="let item of getLinesOfText(message.message); let i = index"
        >
          <p
            class="mb-0 text-gray-500"
            [innerText]="item"
            [ngStyle]="{
              paddingBottom: i === 0 ? '8px' : '',
              paddingTop: i + 1 === getLinesOfText(message.message).length ? '8px' : ''
            }"
          ></p>
        </ng-container>
      </div>

      <ng-template #singleLineMessage>
        <p class="mb-0 text-gray-500 text-center">{{ message.message }}</p>
      </ng-template>

      <div class="flex align-items-center gap-2 mt-4">
        <button
          pButton
          (click)="cd.accept()"
          class="w-8rem justify-content-center"
        >
          <span class="font-medium text-white">{{message.acceptLabel}}</span>
        </button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
