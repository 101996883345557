import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ListCustomerQuery,
  ListCustomerResponse,
} from '@domain/services/customers/dto/list-customer.dto';
import { ShowCustomerResponse } from '@domain/services/customers/dto/read-customer.dto';
import { ChangeStatusPayload, WriteCustomerPayload, WriteCustomerResponse } from '@domain/services/customers/dto/write-customer.dto';
import { CustomerRepository } from '@domain/services/customers/repository/customer.repository';
import { Observable } from 'rxjs';
import { URL_BACKEND } from 'src/app/core/config/url';

@Injectable({
  providedIn: 'root',
})
export class CustomerRepositoryService extends CustomerRepository {
  urlApi = `${URL_BACKEND}/user/customer`;

  constructor(private http: HttpClient) {
    super();
  }

  override show(id: number): Observable<ShowCustomerResponse> {
    return this.http.get<ShowCustomerResponse>(`${this.urlApi}/show`, {
      params: { id: id },
    });
  }

  override list(
    query?: ListCustomerQuery | undefined
  ): Observable<ListCustomerResponse> {
    return this.http.get<ListCustomerResponse>(`${this.urlApi}/list`, {
      params: { ...query },
    });
  }

  override create(payload: WriteCustomerPayload): Observable<WriteCustomerResponse> {
    return this.http.post<WriteCustomerResponse>(this.urlApi, payload);
  }

  override update(payload: WriteCustomerPayload): Observable<WriteCustomerResponse> {
    return this.http.put<WriteCustomerResponse>(this.urlApi, payload);
  }

  override changeStatus(payload: ChangeStatusPayload): Observable<WriteCustomerResponse> {
    return this.http.put<WriteCustomerResponse>(`${this.urlApi}/status`, payload);
  }

}