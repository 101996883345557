<div class="layout-topbar">
  <button
    #menubutton
    class="p-link layout-menu-button layout-topbar-button"
    (click)="layoutService.onMenuToggle()"
  >
    <i class="pi pi-bars"></i>
  </button>

  <button
    #topbarmenubutton
    class="p-link layout-topbar-menu-button layout-topbar-button"
    (click)="layoutService.showProfileSidebar()"
  >
    <i class="pi pi-ellipsis-v"></i>
  </button>

  <div class="flex flex-row" [style]="{gap: '8px', marginLeft: '80px'}">
    @if(headerService.urlToBack) {
      <span class="material-symbols-outlined text-gray-400 cursor-pointer" [style]="{padding: '3.33px'}" (click)="goToBack()">
        arrow_back
      </span>
    }
    <div class="flex flex-column" [style]="{gap: '4px'}">
      <span class="font-semibold text-principal-900" [style]="{fontSize: '20px'}">{{headerService.title}}</span>
      <div class="flex flex-row gap-1">
        <ng-container *ngFor="let item of headerService.breadcrumbRoute; let i = index">
            <span
              class="breadcrumb"
              [class.active]="i === headerService.breadcrumbRoute.length - 1"
            >
              {{item}}
            </span>
            <span *ngIf="i !== headerService.breadcrumbRoute.length - 1" class="breadcrumb separator">/</span>
        </ng-container>
      </div>
    </div>
  </div>

  <div
    #topbarmenu
    class="layout-topbar-menu"
    [ngClass]="{
      'layout-topbar-menu-mobile-active':
        layoutService.state.profileSidebarVisible
    }"
  >
    <button class="p-link layout-topbar-button">
      <i class="pi pi-user"></i>
      <span>Profile</span>
    </button>
  </div>
</div>
