<ul
  class="layout-menu flex flex-column justify-content-between"
  style="display: flex !important; flex-direction: column; height: 100%"
>
  <div class="flex flex-column">
    <li class="flex justify-content-center">
      <img
        src="assets/images/logo.png"
        alt="Logo SVL Abogados"
        class="mt-3"
        [style]="{
          filter: 'brightness(0) invert(100%)',
          width: '88px'
        }"
      />
    </li>

    <ng-container *ngFor="let item of model; let i = index">
      <li
        app-menuitem
        *ngIf="!item.separator"
        [item]="item"
        [index]="i"
        [root]="true"
      ></li>
      <li *ngIf="item.separator" class="menu-separator"></li>
    </ng-container>
  </div>

  <li app-menuitem [item]="itemLogout" [root]="true"></li>
</ul>
