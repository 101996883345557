<p-confirmDialog #cd [key]="dialogKey['CANCEL']">
  <ng-template pTemplate="headless" let-message>
    <span
      class="material-symbols-outlined text-2xl text-gray-200 font-bold fixed top-0 right-0 p-2 cursor-pointer"
      (click)="cd.reject()"
    >
      close
    </span>

    <div
      class="flex flex-column align-items-center p-5 surface-overlay border-round"
    >
      <!-- NOTE: Se usa el atributo `rejectionIcon` para darle color de fondo al ícono -->
      <div
        class="border-circle inline-flex justify-content-center align-items-center h-3rem w-3rem outline-none"
        [ngClass]="message.rejectIcon ?? 'bg-primary'"
      >
        <span class="material-symbols-outlined text-4xl text-white">
          {{ message.icon }}
        </span>
      </div>

      <span class="font-bold text-2xl block mb-2 mt-3">
        {{ message.header }}
      </span>

      <p class="mb-0 text-gray-500">{{ message.message }}</p>

      <div
        class="flex align-items-center gap-2 mt-4 w-full justify-content-center"
      >
        <button
          pButton
          [outlined]="true"
          label="{{ message.rejectLabel || 'Cancelar' }}"
          (click)="cd.accept()"
          class="w-6"
        ></button>

        <button
          pButton
          label="{{ message.acceptLabel || 'Continuar' }}"
          (click)="cd.reject()"
          class="w-6"
        ></button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
