import { AuthProvider } from './auth/auth-provider';
import { CategoryProvider } from './categories/category-provider';
import { ContractsGeneratedProvider } from './contracts-generated/contracts-generated.provider';
import { CustomerProvider } from './customers/customer-provider';
import { HeaderDataProvider } from './header-data/header-data-provider';
import { ManagementFileProvider } from './management-file/management-file-provider';
import { PasswordResetProvider } from './password-reset/password-reset-provider';
import { PlatformContractsProvider } from './platform-contracts/platform-contracts.provider';
import { RoleProvider } from './rol/role-provider';
import { UserProvider } from './users/user-provider';
import { FaqProvider } from './utility-faq/faq.provider';
import { VariableProvider } from './variables/variable-provider';

export const InfrastructureProviders = [
    ...HeaderDataProvider,
    ...AuthProvider,
    ...UserProvider,
    ...RoleProvider,
    ...VariableProvider,
    ...PasswordResetProvider,
    ...VariableProvider,
    ...CategoryProvider,
    ...PlatformContractsProvider,
    ...ManagementFileProvider,
    ...FaqProvider,
    ...CustomerProvider,
    ...ContractsGeneratedProvider
]
