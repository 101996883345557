import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GetRoleResponse } from "@domain/services/role/get-role.dto";
import { RoleRepository } from "@domain/services/role/role.repository";
import { Observable } from "rxjs";
import { URL_BACKEND } from 'src/app/core/config/url';

@Injectable({
  providedIn: 'root',
})
export class RoleRepositoryService extends RoleRepository {
  urlApi = `${URL_BACKEND}/capability/role`;

  constructor(private http: HttpClient) {
    super();
  }

  override get(): Observable<GetRoleResponse> {
    return this.http.get<GetRoleResponse>(`${this.urlApi}/get`);
  }
}