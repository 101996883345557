import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RecoverPasswordResetPayload,
  RecoverPasswordResetResponse,
} from '@domain/services/password-reset/dto/recover.dto';
import {
  ResetPasswordResetPayload,
  ResetPasswordResetResponse,
} from '@domain/services/password-reset/dto/reset.dto';
import {
  UpdatePasswordResetPayload,
  UpdatePasswordResetResponse,
} from '@domain/services/password-reset/dto/update.dto';
import {
  ValidatePasswordResetPayload,
  ValidatePasswordResetResponse,
} from '@domain/services/password-reset/dto/validate.dto';
import { PasswordResetRepository } from '@domain/services/password-reset/repository/password-reset.repository';
import { Observable } from 'rxjs';
import { URL_BACKEND } from 'src/app/core/config/url';

@Injectable({
  providedIn: 'root',
})
export class PasswordResetRepositoryService extends PasswordResetRepository {
  private urlApi = `${URL_BACKEND}/password/reset`;

  constructor(private http: HttpClient) {
    super();
  }

  override recover(
    payload: RecoverPasswordResetPayload,
  ): Observable<RecoverPasswordResetResponse> {
    return this.http.post<RecoverPasswordResetResponse>(
      `${this.urlApi}/recover`,
      { ...payload },
    );
  }

  override validate(
    payload: ValidatePasswordResetPayload,
  ): Observable<ValidatePasswordResetResponse> {
    return this.http.post<ValidatePasswordResetResponse>(
      `${this.urlApi}/validate`,
      { ...payload },
    );
  }

  override reset(
    payload: ResetPasswordResetPayload,
  ): Observable<ResetPasswordResetResponse> {
    return this.http.post<ResetPasswordResetResponse>(
      `${this.urlApi}`,
      { ...payload },
    );
  }

  override update(
    payload: UpdatePasswordResetPayload,
  ): Observable<UpdatePasswordResetResponse> {
    return this.http.put<UpdatePasswordResetResponse>(
      `${this.urlApi}`,
      { ...payload },
    );
  }
}
