import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListFaqQuery, ListFaqResponse } from '@domain/services/utility-faq/dto/list-faq.dto';
import { FaqRepository } from '@domain/services/utility-faq/repository/faq.repository';
import { WriteFAQPayload, WriteFAQResponse } from '@domain/services/utility-faq/dto/write-faq.dto';
import { URL_BACKEND } from 'src/app/core/config/url';
import { ShowFAQResponse } from '@domain/services/utility-faq/dto/read-faq.dto';

@Injectable({
  providedIn: 'root',
})
export class FaqRepositoryService extends FaqRepository {
  urlApi = `${URL_BACKEND}/utility/faq`;

  constructor(private http: HttpClient) {
    super();
  }

  override show(payload: number): Observable<ShowFAQResponse> {
    return this.http.get<ShowFAQResponse>(`${this.urlApi}/show?id=${payload}`);
  }

  override list(query?: ListFaqQuery | undefined): Observable<ListFaqResponse> {
    return this.http.get<ListFaqResponse>(`${this.urlApi}/list`, {
      params: {...query},
    });
  }

  override create(payload: WriteFAQPayload): Observable<WriteFAQResponse> {
    return this.http.post<WriteFAQResponse>(this.urlApi, payload);
  }

  override update(payload: WriteFAQPayload): Observable<WriteFAQResponse> {
    return this.http.put<WriteFAQResponse>(this.urlApi, payload);
  }

  override delete(payload: number): Observable<any> {
    return this.http.delete(`${this.urlApi}?id=${payload}`);
  }

  override sortByIds(payload: number[]): Observable<any> {
    return this.http.post(`${this.urlApi}/sort`, {ids: payload});
  }

}