import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UploadMassivePayload } from '@domain/services/management-file/dto/massive-upload.dto';
import { ManagementFileRepository } from '@domain/services/management-file/repository/management-file.repository';
import { Observable } from 'rxjs';
import { URL_BACKEND } from 'src/app/core/config/url';

@Injectable({
  providedIn: 'root',
})
export class ManagementFileService extends ManagementFileRepository {
  private urlApi = `${URL_BACKEND}/management/file/public`;
  private urlApiPrivate = `${URL_BACKEND}/management/file/private`;

  constructor(private http: HttpClient) {
    super();
  }

  override massive(payload: UploadMassivePayload): Observable<any> {
    const data = new FormData();
    this.loadFormData(data, payload);
    return this.http.post<any>(`${this.urlApi}/massive`, data);
  }

  override massivePrivateFile(payload: UploadMassivePayload): Observable<any> {
    const data = new FormData();
    this.loadFormData(data, payload);
    return this.http.post<any>(`${this.urlApiPrivate}/massive`, data);
  }

  override downloadPrivateFile(fileId: number): Observable<any> {
    return this.http.get<any>(`${this.urlApiPrivate}/download?id=${fileId}`, {
      responseType: 'blob' as 'json', // Configura la respuesta como blob
    });
  }

  private loadFormData(data: FormData, payload: any) {
    data.append('entity_id', payload.entity_id);
    data.append('entity_type', payload.entity_type);
    data.append('entity_bundle', payload.entity_bundle);
    for (let file of payload.files) {
      data.append('files[]', file);
    }
  }
}
