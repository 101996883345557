import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GetUserQuery,
  GetUserResponse,
} from '@domain/services/users/repository/get-user.dto';
import {
  ListUserQuery,
  ListUserResponse,
} from '@domain/services/users/repository/list-user.dto';
import { ShowUserResponse } from '@domain/services/users/repository/show-user.dto';
import { UserRepository } from '@domain/services/users/repository/user.repository';
import { ChangeStatusPayload, WriteUserPayload, WriteUserResponse } from '@domain/services/users/repository/write-user.dto';
import { Observable } from 'rxjs';
import { URL_BACKEND } from 'src/app/core/config/url';

@Injectable({
  providedIn: 'root',
})
export class UserRepositoryService extends UserRepository {
  urlApi = `${URL_BACKEND}/user/person`;

  constructor(private http: HttpClient) {
    super();
  }

  override show(id: number): Observable<ShowUserResponse> {
    return this.http.get<ShowUserResponse>(`${this.urlApi}/show`, {
      params: { id: id },
    });
  }

  override get(query?: GetUserQuery | undefined): Observable<GetUserResponse> {
    return this.http.get<GetUserResponse>(`${this.urlApi}/get`, {
      params: { ...query },
    });
  }

  override list(
    query?: ListUserQuery | undefined
  ): Observable<ListUserResponse> {
    return this.http.get<ListUserResponse>(`${this.urlApi}/list`, {
      params: { ...query },
    });
  }

  override create(payload: WriteUserPayload): Observable<WriteUserResponse> {
    return this.http.post<WriteUserResponse>(this.urlApi, payload);
  }

  override update(payload: WriteUserPayload): Observable<WriteUserResponse> {
    return this.http.put<WriteUserResponse>(this.urlApi, payload);
  }

  override changeStatus(payload: ChangeStatusPayload): Observable<WriteUserResponse> {
    return this.http.put<WriteUserResponse>(`${this.urlApi}/status`, payload);
  }
}
